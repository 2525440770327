<template>
  <div>
    <v-layout v-resize="onResize" column class="formobile">
        <v-data-table :headers="headers" :class="{mobile: isMobile}" :items="itemsWithSno"   hide-default-footer  disable-pagination>
          <template v-slot:header.line="{ header }">
            {{ header.text }} 
                  <span style="display: flex;">
                     <span>$</span>
                     <span style="display: flex;">
                      <span class="material-icons topheads green--text">arrow_upward</span>/
                      <span class="material-icons topheads red--text">arrow_downward</span>
                     </span>
                  </span> 
            
          </template>
         <template v-slot:item="{ item,index }"  >
           <tr class="bannertr" v-if="!isMobile && ((index+1) %24 == 1 && index > 1)">
             <td :colspan="headers.length">
              <div class="adcontainer" v-if="!showpreviewbanner">
                <iframe  src='https://api.damnnbank.com/api/showbanners?website=sportrade&width=700&height=90' frameborder='0' scrolling='no' width='720' height='90' allow='autoplay'></iframe>
              </div>
              <div class="adcontainer" v-else>
                <iframe ref="myIframe" :src=iframeurl frameborder='0' scrolling='no' width='720' height='90' allow='autoplay'></iframe>
              </div>
              </td>
           </tr>
           <tr v-if="!isMobile">
            <td :class="{'Active':item.player_status == 'Active','Inctive':item.player_status == 'Inactive'}" class="relative">
               <span>{{ item.sno }}</span>
               <span v-if="item.sno<7 && fire"  class="fire-player">🔥</span>

               </td>
            <td class="text-left">
              <div class="mt-3 mb-2" :class="{'makediffcircle': $route.name == 'dividend'}">
                <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.id }}" v-if="item.type == 'team'">
                 <v-avatar class="cusavatar"><img :src="item.thumbnail_image"   :alt="item.name"></v-avatar>  
                 <span class="playername">{{ item.name }}</span>
                 <span v-if="game == '' && item.soldfromDamnn == 1" class="bold">(UX)</span>
                 <span v-if="game == '' && item.soldfromDamnn == 0" class="bold">(MP)</span>
                </router-link>
                <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.id }}" v-else>
                 <v-avatar class="cusavatar"><img :src="item.thumbnail_image"   :alt="item.name"></v-avatar>  
                 
                 <span class="playername">{{ item.name }}</span>
                 <span v-if="game == '' && item.soldfromDamnn == 1" class="bold">(UX)</span>
                 <span v-if="game == '' && item.soldfromDamnn == 0" class="bold">(MP)</span>
                 
                 <img :src="fireimage" v-if="item.trophy == 1">
                </router-link>
              </div>
            </td>
             <td>
              <div class="flex" v-if="item.nationality">
                 <span class="country">{{ item.nationality }}</span>
              </div>
            </td>
            <td class="comfont text-center">
              <v-avatar><img :src=item.nextmatchlogo :title=item.nextmatchteam /></v-avatar>
            </td>
            <td class="comfont text-center"><span :class="item.genniecolor" v-if="item.genniepred">{{ item.genniepred }}</span></td>
            <td class="comfont text-center"><span :class="item.genniecolor" v-if="item.gennie">{{ item.gennie }}</span></td>
            <td class="comfont text-center graphimg">
              <span v-if="item.twnty_four_Hrs.flag != '' ">
                 <img v-if="item.twnty_four_Hrs.flag == 'positive'" :src="getimage('green')">
                 <img v-else-if="item.twnty_four_Hrs.flag == 'negative'" :src="getimage('red')">
                 <img v-else :src="getimage('orange')">
               </span>
            </td>
            <td class="comfont text-center">{{ numberWithCommas(item.availability) }}</td>
            
            <td class="comfont text-center">
              <span class="contentdata displaycurr">
                <span class="displaycurr" v-html="currency(item.price)"></span>
                <span>
                  <span class="greenvalue" v-if="item.twnty_four_Hrs.flag == 'positive'"> 
                    <span>(<span >+</span></span><div class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
                  </span>
                  <span class="redvalue" v-if="item.twnty_four_Hrs.flag == 'negative'"> 
                    <span>(-</span> <div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
                  </span>
                  <span class="orangevalue" v-if="item.twnty_four_Hrs.flag == 'orange'"> 
                    <span>(</span> <div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
                  </span>
                </span>
              </span>
            </td>
            <td class="daysago" v-if="typeof item.last_played != 'undefined'">
               <span class="dayspan" v-if="item.last_played != '0'">
                 {{ item.last_played }}
               </span>
               <span class="dayspangreen" v-if="item.last_played == '0'" title="Today">
                 T
               </span>
            </td>
            <td class="comfont">
               <template>
                  <div v-if="item.player_status == 'Active' || item.status == 'Active'">
                      <v-btn color="green" @click="openingamepop" class="actions">In Game</v-btn>
                  </div> 
                          <div v-else>
                           <div v-if="suspendedShow(item.price)">
                            <v-btn v-if="item.type=='player'" color="themebtn" @click="buyplayer(item.keyval)" class="actions">Buy Now</v-btn>
                            <v-btn v-else color="themebtn" @click="buyteam(item.keyval)" class="actions">Buy Now</v-btn>
                          </div>  
                          <v-btn  v-else color="primary text-center sus"  @click="buyplayer(item.keyval)" class="actions"> Suspended</v-btn>
                          </div>  
                  
              </template>
            </td>
          </tr>
       <tr v-else>
            <td>
               <div class="d-flex listing-result">
                 <div class="listing-result-sub-1 player-info">
                   <div class="play-lst-image">
                     <router-link :to="{name: 'team', params:{ id: item.id }}" v-if="item.type == 'team'">
                        <img :src="item.thumbnail_image" :alt="item.name">
                     </router-link>    
                     <router-link :to="{name: 'player', params:{ id: item.id }}" v-else>
                        <img :src="item.thumbnail_image" :alt="item.name">
                     </router-link>    
                   </div>
                     <div class="play-lst-name">
                        <router-link class="playernamemob" :to="{name: 'team', params:{ id: item.id }}" v-if="item.type == 'team'">
                          {{ item.name }}
                        </router-link>    
                        <router-link class="playernamemob" :to="{name: 'player', params:{ id: item.id }}" v-else>
                          {{ item.name }}
                        </router-link>    
                     </div>
                      <div class="play-lst-location">
                       <span v-if="item.sno<7 && fire"  class="fire-player">🔥</span> 
                       <span class="country" v-if="item.nationality">{{ item.nationality }}</span>
                     </div>
                   </div>
                   <div class="listing-result-sub-1 player-graph">
                     <div class="play-lst-graph-image">
                       <span v-if="item.twnty_four_Hrs.flag != '' ">
                         <img v-if="item.twnty_four_Hrs.flag == 'positive'" :src="getimage('green')">
                         <img v-else-if="item.twnty_four_Hrs.flag == 'negative'" :src="getimage('red')">
                         <img v-else :src="getimage('orange')">
                       </span>
                     </div>
                   </div>
                   <div class="listing-result-sub-1 player-data">
                     <div class="play-lst-current">
                       <label>CURRENT</label>
                       <div class="value">
                         <span class="contentdata displaycurr">
                           <span class="displaycurr" v-html="currency(item.price)"></span>
                           <span>
                           <span class="greenvalue" v-if="item.twnty_four_Hrs.flag == 'positive'"> 
                            <span>(<span >+</span></span><div class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
                           </span>
                           <span class="redvalue" v-if="item.twnty_four_Hrs.flag == 'negative'"> 
                            <span>(-</span> <div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
                           </span>
                           <span class="orangevalue" v-if="item.twnty_four_Hrs.flag == 'orange'"> 
                            <span>(</span> <div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
                           </span>
                           </span>
                          </span>
                        </div>
                      </div>
                      <div class="play-lst-dx-unit">
                        <label>DX Units To Buy</label>
                        <div class="value">{{ numberWithCommas(item.availability) }}</div>
                      </div>
                      <div class="play-lst-dx-unit">
                        <template>
                           <div v-if="item.player_status == 'Active'">
                             <v-btn color="green" @click="openingamepop" class="actions">In Game</v-btn>
                          
                          </div> 
                          <div v-else>
                           <div v-if="suspendedShow(item.price)">
                            <v-btn v-if="item.type=='player'" color="themebtn" @click="buyplayer(item.keyval)" class="actions">Buy Now</v-btn>
                            <v-btn v-else color="themebtn" @click="buyteam(item.keyval)" class="actions">Buy Now</v-btn>
                          </div>  
                          <v-btn  v-else color="primary text-center sus"  @click="buyplayer(item.keyval)" class="actions"> Suspended</v-btn>
                          </div>  
                        </template>
                      </div>
                    </div>
                  </div>
                 </td>
               </tr>
        </template>   
       </v-data-table>
          </v-layout> 
          <buy-team-modal :teamdialog.sync="dialogteam" :selectedPlayer="selectedPlayer" :language="language" />
          <buy-player-modal :dialog.sync="showplayerdialog" :selectedPlayer="selectedPlayer" :language="language" />   
  </div>      
</template>
<script>

export default{
     props:{
       itemsWithSno:{type: Array},
       headers:{type: Array},
       game:{type: String},
       fire:{type: Boolean}
   },
   data(){
       return{
           isMobile: false,
           showplayerdialog:false,
           selectedPlayer:null,
           language:'',
           dialogteam:false,
           fireimage:require("@/assets/trophy.png"),
           showpreviewbanner:false,
           iframeurl:''
       };
   },
   mounted(){
    if(typeof this.$route.query.preview != "undefined")
    {
       let id = this.$route.query.preview;
       this.showpreviewbanner = true;
       this.iframeurl = 'https://api.damnnbank.com/api/bannerpreview/'+id;
       //this.$refs.myIframe.scrollIntoView({ behavior: 'smooth' })
    }
   },
   methods:{
      openingamepop(){
         this.$root.$refs.Master.openInGame();
    }, 
     onResize() {
          if (window.innerWidth < 769)
            this.isMobile = true;
          else
            this.isMobile = false;
     },
     numberWithCommas(x){
       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, 
       getimage(type){
       var image = '';
       var imgnumber = Math.floor(Math.random() * 6) + 1;
       if(type == 'green'){
           image = "green"+imgnumber+".png";
       }
       else if(type == 'orange'){
           image = "orange.png";
       }
       else{
           image = "red"+imgnumber+".png";
       }
       var fullpath = require(`../assets/`+image);

      return fullpath;   
    },
    suspendedShow(price){
         var suspended = true;
         var getsetting = this.$store.getters.getcurrencySetting;
         var checkprice = 0;

         if(getsetting.dammn_coin == 1)
         {
             checkprice = price*getsetting.dammn_coin_multiple;      

            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                 suspended = false;
              }
            }  
         }
         else
         {
            checkprice = price;      
            if(checkprice < getsetting.dc_deduction_value)
            {
                 suspended = false;
            }
         }

         return suspended;
     },
     buyteam(index){ 
         this.error          = '';
         this.errorText      = '';
         this.inputunit      = '';
         this.total          = 0;  
         var price           = this.itemsWithSno[index].price;
         var getsetting      = this.$store.getters.getcurrencySetting;
         this.selectedPlayer = this.itemsWithSno[index];

         if(getsetting.dammn_coin == 1)
         {
            var checkprice = price*getsetting.dammn_coin_multiple;      
            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Team is suspended due to their amount is less then DC 50';
              }
            }  
         }
          else
         {
           checkprice = price;   
           if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Team is suspended due to their amount is less then $0.05';
              }
         } 
         if(this.$route.name == "dividend")
         {
           if(this.$store.getters.getdividendBalance < price)
           {
             this.selectedPlayer = '';
             this.language = 'Insufficient Dividend Balance';
           }
         } 
         this.dialogteam = true; 
     }, 
       
     buyplayer(index){ 
         this.error          = '';
         this.errorText      = '';
         this.inputunit      = '';
         this.total          = 0;  
         var price           = this.itemsWithSno[index].price;
         var getsetting      = this.$store.getters.getcurrencySetting;
         this.selectedPlayer = this.itemsWithSno[index];
         var checkprice      = 0; 
         if(getsetting.dammn_coin == 1)
         {
            checkprice = price*getsetting.dammn_coin_multiple;      

            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Player is suspended due to their amount is less then DC 50';
              }
            }  
         }
         else
         {
           checkprice = price;   
           if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Player is suspended due to their amount is less then $0.05';
              }
         }
         if(this.$route.name == "dividend")
         {
           if(this.$store.getters.getdividendBalance < price)
           {
             this.selectedPlayer = '';
             this.language = 'Insufficient Dividend Balance';
           }
         } 
         this.showplayerdialog = true; 
     },
   }
}
</script>
<style scoped>
.topheads{font-size: 16px;}
.redclass{color: red; font-size: 11px;}
.orangeclass{color:orange; font-size: 11px;}
.greenclass{color: green; font-size: 11px;}
</style>
<style >
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.bannertr:hover{background: none !important;}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.bannertr td{text-align: center;}
.adcontainer {
    display: flex;
    justify-content: center;
}
.cusavatar{width: 60px !important; height: 60px !important;}
.daysago{font-size: 12px !important;}
span.dayspan {
    display: block;
    width: 25px;
    border: 1px solid yellow;
    text-align: center;
    background: yellow;
    font-style: italic;
}
span.dayspangreen{
  display: block;
    width: 25px;
    border: 1px solid green;
    text-align: center;
    background: green;
    color: white;
    font-style: italic;
}
</style>