<template>
  <div id="damnnpx">
    <gamelist v-if="showgamelist"></gamelist>
    <div v-else>
     <v-tabs class="tabber" color="themebtn" @change="tabchange" v-model="tabing">
       <v-tab>
         {{tabone}}
       </v-tab>
       <v-tab><div style="margin-right:4px" class="displaycurrency" v-html="currencysymbol()"></div>&nbsp; &nbsp; Gainers</v-tab>
       <v-tab><div style="margin-right:4px" class="displaycurrency" v-html="currencysymbol()"></div> Losers</v-tab>
       <v-tab class="greencolor"> <span class="material-icons" >trending_up</span> Live</v-tab>
     </v-tabs>
     <div class="filterdiv" v-if="game != '' && tabing == 0">
      <div class="filtersub">
          <v-select v-if="game == 'Tennis'" class="teamselection mx-4" @change="filterdata" :items="gender" item-text="name" item-value="id" label="Player Type" v-model="playertype" hide-details></v-select>
          <v-select v-else class="teamselection mx-4" @change="filterdata" :items="teams" item-text="name" item-value="id" label="Select Team" v-model="team" hide-details></v-select>
      </div> 
      <div class="filtersub">
        <!--@keyup="searchbyname"-->
        <v-text-field  v-model="search" ref="search" :disabled="searchdisable" label="Search Player" class="mx-4 searchplayer"></v-text-field>
      </div>
      <div class="filtersub filtercontrol">
          <div class="filtercontrolsub">Price</div>
          <div class="filtercontrolsub">
            <v-radio-group v-model="price" class="mylegeneds" row @change="filterdata">  
            <v-radio label="High" value="high"></v-radio>
            <v-radio label="Low" value="low"></v-radio>
            </v-radio-group>
          </div>
          <div class="filtercontrolsub">
            <v-btn color="primary" @click="resetfilter" class="actions filter">Reset</v-btn>
          </div>
      </div>
    </div>  
    <div v-if="tabing == 0">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno"></playertable> 
      <infinite-loading ref="infiniteLoading" v-if="search == ''" :identifier="infiniteId" @infinite="getList"></infinite-loading>
   </div>
   <div v-if="tabing == 1">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno"></playertable> 
  </div>
  <div v-if="tabing == 2">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno"></playertable> 
  </div>
  <div v-if="tabing == 3">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno"></playertable>  
  </div>
   <v-overlay color="white" :value="overlay">
      <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
   </v-overlay>
  </div>
 </div>
</template>

<script>
import Gamelist from '../components/Gamelist.vue';
import Playertable from '../components/Playertable.vue';

export default {
  name: "Damnnpx",
  components:{ Gamelist,Playertable },
  data() {
    return {
      playertype:'',
      gender:['Both','Male','Female'],
      mobileBreakpoint: 600,
      price:'',
      error: false,
      errorText:'',
      tabing:0,
      overlay: false,
      showgamelist:false,
      desserts:[],
      search:'', 
      showplayerdialog:false,
      dialogteam:false,
      selectedPlayer:null,
      readonly:false,
      page:1,
      team:'',
      teams: [],
      game:'',
      league:'',
      gameList:[],
      language:'',
      hotornot:false,
      tabone:'',
      infiniteId: +new Date(),
      comefromsearch:'',
      isMobile: false,
      searchdisable:false
    };
  },
  mounted() {
    this.overlay = true;
     
      this.hotornot = false;
      this.tabone = 'All';
    
    var param = this.$route.params.sport;
    if(typeof param != "undefined") {
       this.game = param;
    }
    if(typeof this.$route.params.league != "undefined"){
       this.league = this.$route.params.league; 
    } 
    //this.gamesListing();
   
  },
  computed: {
     headers(){
        let headers = [
              { text:"#", value: "sno", width:"5%"},
              { text:"Name",align: "name",value: "name",sortable: true, width:"30%"},
              { text:"", value: "nationality",sortable: true, width:"20%"},
              { text:"Next Match",value:"",sortable:false, width:"5%" },
              { text:"Gennie Prediction",value:"",sortable:false, width:"5%" },
              { text:"Gennie",value:"line",sortable:false, width:"5%" },
              { text:"", value: "",sortable: false, width:"11%"},
              { text:"Units To Buy", value:"availability",sortable: true, width:"8%"},
              { text:"Current",value:"price",sortable:true, width:"10%"},
              { text:"Days Last Game",value:"last_played",sortable:true,width:"11%"},
              { text:"Buy",value:"#", sortable: false, width:"12%"}
         ]
         return headers
       },
       
       itemsWithSno() {
          return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
       },
       
  },
  watch: {
    $route() {
      this.page = 1;
      this.tabing = 0;
      this.overlay = true;
      this.desserts = [];
      this.league = '';
      this.team = '';  
      this.hotornot = false;
      this.tabone = 'All';
       
      if(this.$route.params.sport){
        this.game = this.$route.params.sport;
      }
      else{
        this.game = '';
      }
      if(typeof this.$route.params.league != "undefined"){
       this.league = this.$route.params.league; 
      } 
      else{
        this.league = '';
      }
      this.changeType();
   },
   search(){
     this.searchbyname();
   }
  },    
  methods: {
    
   changeType() {
      this.page = 1;
      this.desserts = [];
       this.$nextTick(() => {
        this.$refs.infiniteLoading.attemptLoad();
      });
      this.infiniteId += 1;
    },
    
    
    gamesListing(){
        this.$store.dispatch("getGameList").then(() => {
         this.gameList = this.$store.getters.getGameList;
      })
    },
    tabchange(){
       this.page = 1;
       this.game = '';
       this.team = '';
       this.search = ''; 
       this.overlay = true;
       this.desserts = [];
     
     if(this.$route.params.sport){
        this.game = this.$route.params.sport;
      }
       if(this.tabing == 0){
         //this.getList();
       }
       else if(this.tabing == 1){
         this.gainerList();
       }
       else if(this.tabing == 2){
         this.loserList();
       }
       else{
         this.liveuserlist();
       }
    },
    resetfilter(){
      
       //this.game = '';
       this.price = '';
       this.page = 1;
       this.overlay = true; 
       this.search = '';
       this.desserts = [];
       this.getList();
    },
    specialfilterdata(){
      this.team = '';
      this.search = '';
      this.filterdata();
    },
    filterdata(){
       this.infiniteId += 1;
       this.page = 1;
       this.desserts = [];
       this.overlay = true;
       this.search = ''; 
       if(this.tabing == 0){
         //this.getList();
       }
       else if(this.tabing == 1){
         this.gainerList();
       }
       else{
         this.loserList();
       }
    },
    getTeamListing(){
       var sports = ""
       var league = "";
       if(this.game != '') 
       { 
         sports = this.game.toLowerCase();
       } 
       if(this.league != "")  
       {
         league = this.league;
       } 
       this.teams = [{id:0,name:"All Team"}];
       this.$store.dispatch("getTeamlisting",{game:sports,league:league}).then((response) => 
          { 
             //this.teams.push(response.teams);  
             for(var i = 0; i < response.teams.length; i++)
             {
               this.teams.push({ id:response.teams[i].id, name:response.teams[i].name });
               //console.log(this.teams);
             }
          }); 
    },
    searchbyname(){
       if(this.search.length >= 3 )
       {
        this.searchdisable = true;
        this.desserts = [];
        this.overlay = true; 
        this.comefromsearch = 'Yes';
        var sports = "";
        var league = "";
        var parameter = "page="+this.page;
        this.page = 1;
        if(this.game != '')
        {
          sports = this.game.toLowerCase(); 
          parameter = parameter+"&sport="+sports;
        } 
        if(this.league != '')
        {
          league = this.league;
          parameter = parameter+"&league="+league; 
        }
        
        if(this.team != '')
        {
            parameter = parameter+"&team="+this.team;
        }
        if(this.search != '')
        {
            parameter = parameter+"&q="+this.search;
        } 
        if(this.price != '')
        {
            parameter = parameter+"&sort_by="+this.price;
        } 
        this.$store.dispatch("getDamnnPxList",{paramters:parameter}).then((response) => 
        {
            this.overlay = false; 
              this.searchdisable = false; 
            if(response.players.length > 0)
            {
                this.desserts = response.players;
                 this.$nextTick(() => 
                 {
                   this.$refs["search"].focus()
                 });
            }
        });
       }
       
    }, 
    getList($state){ 
       var sports = "";
       var league = "";
       var gender = "";
       var parameter = "page="+this.page;
       if(this.playertype != '')
       {
         if(this.playertype != 'Both')
          {
            gender = this.playertype;
            parameter = parameter+"&gender="+gender;   
          }
       }
       if(this.game != '')
       {
         sports = this.game.toLowerCase(); 
         parameter = parameter+"&sport="+sports;
       } 
       if(this.league != '')
       {
         league = this.league;
         parameter = parameter+"&league="+league; 
       }
       if(this.team != '')
       {
          parameter = parameter+"&team="+this.team;
       }
       
       if(this.price != '')
       {
          parameter = parameter+"&sort_by="+this.price;
       } 
       
       if(typeof this.$route.query.preview == "undefined")
      {
        this.getTeamListing();
      }
       
       this.$store.dispatch("getDamnnPxList",{paramters:parameter}).then((response) => 
       {
              this.overlay = false; 
              if(response.players.length > 0)
              {
                 this.page += 1;
                 if(this.comefromsearch != '')
                {
                 // console.log("i am in if");
                  this.desserts = [];  
                  this.desserts = response.players;
                }  
                else
                {
                  //console.log("i am in else");
                  this.desserts.push(...response.players);
                }
                
                this.comefromsearch = '';
                //this.desserts = this.desserts.filter(function(item){ return item.price != "0.00"})
                $state.loaded();
              }
              else
              {
                $state.complete();
              }
          });
      
     },
    loserList($state){ 
       var sports = "";
       var parameter = "";
        var league = "";
       if(this.game != '')
       {
         sports = this.game.toLowerCase(); 
         parameter = "/"+sports+"?page="+this.page;
       } 
       if(this.team != '')
       {
          parameter = parameter+"&team="+this.team;
       }
        if(this.league != '')
       {
         league = this.league;
         parameter = parameter+"&league="+league; 
       }
       if(this.search != '')
       {
          parameter = parameter+"&q="+this.search;
       } 
       if(this.price != '')
       {
          parameter = parameter+"&sort_by="+this.price;
       } 
      
      this.getTeamListing();  
      this.$store.dispatch("getLoserList",{"list":parameter}).then((response) => 
      {
          this.overlay = false;
          if(response.players.length > 0)
          {
             this.page += 1;
             this.desserts = response.players;
             $state.loaded();
          }
          else
          {
            $state.complete();
          }
       });
        
    },
    liveuserlist(){
       var sports = "";
       var parameter = "";
        var league = "";
       if(this.game != '')
       {
         sports = this.game.toLowerCase(); 
         parameter = "/"+sports+"?page="+this.page;
       } 
       if(this.team != '')
       {
          parameter = parameter+"&team="+this.team;
       }
        if(this.league != '')
       {
         league = this.league;
         parameter = parameter+"&league="+league; 
       }
       if(this.search != '')
       {
          parameter = parameter+"&q="+this.search;
       } 
       if(this.price != '')
       {
          parameter = parameter+"&sort_by="+this.price;
       } 
      
      this.getTeamListing();  
      this.$store.dispatch("getLiveuserList",{"list":parameter}).then((response) => 
      {
          this.overlay = false;
           
          if(response.data.players.length > 0)
          {
            // this.page += 1;
             this.desserts = response.data.players;
          }
          else
          {
            //$state.complete();
          }
       });
         
    },
    gainerList($state){ 
       var sports = "";
       var parameter = "";
       var league = "";
       if(this.game != '')
       {
         sports = this.game.toLowerCase(); 
         parameter = "/"+sports+"?page="+this.page;
       }
        if(this.league != '')
       {
         league = this.league;
         parameter = parameter+"&league="+league; 
       } 
       if(this.team != '')
       {
          parameter = parameter+"&team="+this.team;
       }
       if(this.search != '')
       {
          parameter = parameter+"&q="+this.search;
       } 
       if(this.price != '')
       {
          parameter = parameter+"&sort_by="+this.price;
       }  
        
          this.getTeamListing();
          this.$store.dispatch("getGainerList",{"list":parameter}).then((response) => 
          {
              this.overlay = false;
              if(response.players.length > 0)
              {
                this.page += 1;
                this.desserts = response.players;
                
                $state.loaded();
              }
              else
              {
               $state.complete();
              }
          });
       
    },
     
     
  }
};
</script>
<style>
td.Inctive span{
   
    border-radius: 50%;
    width: 22px;
    display: block;
    text-align: center;
    font-size: 12px;
}
td.Active span {
    background: green;
    border-radius: 50%;
    width: 22px;
    display: block;
    text-align: center;
    color: white;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
}
.greencolor.v-tab {
    color: green !important;
}
thead.v-data-table-header.v-data-table-header-mobile {   display: none;}
.d-flex.listing-result {display: flex;flex-wrap: wrap; width: 98%;margin:10px auto;box-shadow: 0 0 2px 3px #ddd;padding: 5px 8px; border-radius: 10px; align-items: center;}
  .listing-result-sub-1.player-info { flex: 0 0 40%;}
  .listing-result {display: flex; flex-wrap: wrap;}
  .listing-result-sub-1.player-graph {flex: 0 0 25%;}
  .listing-result-sub-1.player-data { flex: 0 0 35%;}
  .listing-result img {  max-width: 80%; margin: auto;     max-height: 60px;}
  .play-lst-current {margin-bottom: 15px;}
 .listing-result-sub-1.player-data .buy-now { background-color: #247cdd; border-color: #247cdd;padding: 5px 0px;color: white;font-weight: bold;text-align: center;
    border-radius: 3px;}
  .listing-result-sub-1 label{font-size: 14px;color:#646464;}
.listing-result-sub-1 div.value,.listing-result-sub-1 div.value span{font-size: 13px;}
.listing-result .play-lst-graph-image img {
    max-width: 75%;
    max-height: 40px;
}
 @media screen and (max-width: 768px) {
   .playernamemob{color: black !important;}
   span.country {
    font-size: 11px;
}
    .formobile .mobile table tr td{border: none !important;}
      .mobile table.v-table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile table.v-table tr:nth-child(odd) {
        border-left: 6px solid deeppink;
      }

      .mobile table.v-table tr:nth-child(even) {
        border-left: 6px solid cyan;
      }

      .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
      }

      .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;

      }
      .v-datatable__actions__select
      {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
      }
      .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
      }

    }
     
</style>
 