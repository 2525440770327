import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

axios.defaults.baseURL = process.env.VUE_APP_BASEAPI;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    name: '',
    email: '',
    scores: null,
    balance: 0,
    inbank:0,
    kels:0,
    rates: null,
    transactions: null,
    adminTransactions: null,
    adminGameTransactions: null,
    rewards: null,
    adminRewards: null,
    adminFeatures: null,
    isAdmin: false,
    giftPayBalance: null,
    paymentMethods: null,
    paymentAmounts: null,
    gamelist:[],
    teamlist:[],
    fantasygamelist:[],
    userid:null,
    avatar:'',
    currencySetting:[],
    coin: localStorage.getItem('coin') || 0,
    coinvalue: localStorage.getItem('coinvalue') || 1,
    userdetails:[],
    dividendbalance:0,
    bonusbank:0
  },
  mutations: {
    setBonusbank(state,payload){
       state.bonusbank = payload;
    },
    setDividendBalance(state,payload){
       state.dividendbalance = payload; 
    },
    setUserdetails(state,payload){
       state.userdetails = payload;
    },
    currencySetting(state,payload){
       state.currencySetting = payload; 
    },
    setAvatar(state,payload){
       state.avatar = payload;
    },
    setUserid(state,payload){
       state.userid = payload; 
    },
    setFantasyGameList(state,payload){
      state.fantasygamelist = payload; 
    },
    setGameList(state,payload){
       state.gamelist = payload; 
    },
    setTeamList(state,payload){
      state.teamlist = payload; 
    },
    retrieveToken(state, token) {
      state.token = token;
    },
    destroyToken(state) {
      state.token = null;
    },
    setName(state, name) {
      state.name = name;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setScores(state, scores) {
      state.scores = scores;
    },
    setInbank(state, balance) {
      state.inbank = balance;
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setRates(state, rates) {
      state.rates = rates;
    },
    setGiftPayBalance(state, giftPayBalance) {
      state.giftPayBalance = giftPayBalance;
    },
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    setFloorplans(state, adminFloorplans) {
      state.adminFloorplans = adminFloorplans;
    },
    setAdminTransactions(state, adminTransactions) {
      state.adminTransactions = adminTransactions;
    },
    setUserListing(state, adminUserlisting) {
      state.adminUserlisting = adminUserlisting;
    },
    setAdminGameTransactions(state, adminGameTransactions) {
      state.adminGameTransactions = adminGameTransactions;
    },
    setAdminFeatures(state, adminFeatures) {
      state.adminFeatures = adminFeatures;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
    setPaymentAmounts(state, paymentAmounts) {
      state.paymentAmounts = paymentAmounts;
    },
    setKels(state, kels) {
      state.kels = kels;
    },
  },
  getters: {
    kels(state){
      return state.kels; 
    },
    getBonusBank(state){
      return state.bonusbank;
    },
    getdividendBalance(state){
       return state.dividendbalance;
    },
    getUserdetails(state){
       return state.userdetails;
    },
    getcurrencySetting(state){
       return state.currencySetting;
    },                                                                   
    getAvatar(state){
       return state.avatar; 
    },
    getUserid(state){
      return state.userid;   
    },
    getFantasyGameList(state){
      return state.fantasygamelist; 
    },
    getGameList(state){
      return state.gamelist; 
    },
    getTeamList(state){
      return state.teamlist; 
    },
    loggedIn(state) {
      return state.token !== null;
    },
    name(state) {
      return state.name;
    },
    email(state) {
      return state.email;
    },
    scores(state) {
      return state.scores;
    },
    inbank(state){
      return state.inbank; 
    },
    balance(state) {
      return state.balance;
    },
    rates(state) {
      return state.rates;
    },
    giftPayBalance(state) {
      return state.giftPayBalance;
    },
    transactions(state) {
      return state.transactions;
    },
    adminTransactions(state) {
      return state.adminTransactions;
    },
    adminFloorplans(state) {
      return state.adminFloorplans;
    },
    adminUserlisting(state) {
      return state.adminUserlisting;
    },
    adminGameTransactions(state) {
      return state.adminGameTransactions;
    },
    
    adminFeatures(state) {
      return state.adminFeatures;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    paymentMethods(state) {
      return state.paymentMethods;
    },
    paymentAmounts(state) {
      return state.paymentAmounts;
    },
  },
  actions: {
    register(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/user/create', {
          firstname: data.firstname,
          lastname: data.lastname,
          username   : data.username,
          name       : data.firstname+" "+data.lastname,
          email      : data.email,
          password   : data.password,
          region     : data.region,
          referral   : data.referral,
          qrcodescan : data.qrcodescan,
          dob        : data.dob,
          gender     : data.gender,
          cityname   : data.cityname,
          suburb     : data.suburb,
          stateid    : data.stateid,
          dollarref  : data.dollarref
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    destroyToken(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('logout').then(response => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('coin');
            localStorage.removeItem('coinvalue');
            localStorage.removeItem('comefrom');
            context.commit('destroyToken');
            context.commit('setAvatar','');
            resolve(response);
          }).catch(error => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('coin');
            localStorage.removeItem('coinvalue');
            localStorage.removeItem('comefrom');
            context.commit('destroyToken');
            reject(error);
          })
        })
      }
    },
    retrieveToken(context, credentials) {
      
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', {
          email: credentials.username,
          password: credentials.password,
          damnnId: credentials.damnnId
        }).then(response => {
          
          if(response.data.status)
          {            
            const token = response.data.result.access_token;
            localStorage.setItem('access_token', token);
            context.commit('retrieveToken', token);
            resolve(response);
          }
          else
          {
            resolve(response);
          }
          
        }).catch(error => {
          
          reject(error);
        })
      })
    },
    retrieveUserDetails(context) {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('user').then(response => 
          { 
            const name = response.data.data.name;
            const email = response.data.data.email;
            const isAdmin = response.data.role == 1 ? true : false
            if(typeof response.data.data.avatar != 'undefined')
            {
               if(response.data.data.avatar != '')
               {
                context.commit('setAvatar',response.data.data.avatar);
               }
            }
            context.commit('setUserid',response.data.data.id);
            context.commit('setName', name);
            context.commit('setEmail', email);
            context.commit('setIsAdmin', isAdmin);
            context.commit('setUserdetails',response.data.data);
            context.dispatch("getCurrency");
            context.dispatch('retrieveBalance');
            context.dispatch("getGameList");
            context.dispatch("getFantasyGameList");
            context.dispatch("getTeamList");
            resolve(response); 
            
          }).catch(error => {
            context.commit('setName', '');
            context.commit('setEmail', '');
            context.commit('setIsAdmin', false);
            
            reject(error);
          })
        })
      }
    },
    getCurrency(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) 
      {
        return new Promise((resolve, reject) => {
          Vue.axios.get('coin_settings').then(response => {
            localStorage.setItem('coin', response.data.data.dammn_coin);
            localStorage.setItem('coinvalue', response.data.data.dammn_coin_multiple);
           
            context.commit('currencySetting',response.data.data);
            
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }  
    },
    retrieveScores(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('score/all').then(response => {
            const scores = response.data.data;
            context.commit('setScores', scores);
            resolve(response.data);
          }).catch(error => {
            context.commit('setScores');
            reject(error);
          })
        })
      }
    },
    retrieveBalance(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var userid = context.getters.getUserid;
          
          Vue.axios.get('wallet/user-balance?user_id='+userid).then(response => {
            const balance = response.data.data.balance;
            const inbank  = response.data.data.inbank;
            const kels    = response.data.data.kels;
            const bonus   = response.data.data.bonus;

            context.commit('setBalance', balance);
            context.commit('setInbank', inbank);
            context.commit('setKels', kels);
            context.commit('setBonusbank', bonus);
            resolve(response.data);
          }).catch(error => {
            context.commit('setBalance');
            reject(error);
          })
        })
      }
    },
    retrieveRates(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('rates').then(response => {
            const rates = response.data.data;
            context.commit('setRates', rates);
            resolve(response.data);
          }).catch(error => {
            context.commit('setRates');
            reject(error);
          })
        })
      }
    },
    
    retrieveTransactions(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('bank/transactions').then(response => {
            const transactions = response.data.data;
            context.commit('setTransactions', transactions);
            resolve(response.data);
          }).catch(error => {
            context.commit('setTransactions');
            reject(error);
          })
        })
      }
    },
    retrieveAdminTransactions(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('bank/transactions').then(response => {
            const adminTransactions = response.data.data;
            context.commit('setAdminTransactions', adminTransactions);
            resolve(response.data);
          }).catch(error => {
            context.commit('setAdminTransactions');
            reject(error);
          })
        })
      }
    },
    
    retrieveUserListings(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('userlisting').then(response => {

            const adminUserlisting = response.data.data;
            context.commit('setUserListing', adminUserlisting);
            resolve(response.data);
          }).catch(error => {
            context.commit('setUserListing');
            reject(error);
          })
        })
      }
    },
    retrieveAdminGameTransactions(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('game/admin/transactions').then(response => {
            const adminGameTransactions = response.data.data;
            context.commit('setAdminGameTransactions', adminGameTransactions);
            resolve(response.data);
          }).catch(error => {
            context.commit('setAdminGameTransactions');
            reject(error);
          })
        })
      }
    },
    retrieveRewards(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('rewards').then(response => {
            const rewards = response.data.data;
            context.commit('setRewards', rewards);
            resolve(response.data);
          }).catch(error => {
            context.commit('setRewards');
            reject(error);
          })
        })
      }
    },
    retrieveAdminRewards(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('rewards').then(response => {
            const adminRewards= response.data.data;
            context.commit('setAdminRewards', adminRewards);
            resolve(response.data);
          }).catch(error => {
            context.commit('setAdminRewards');
            reject(error);
          })
        })
      }
    },
    retrieveAdminFeatures(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('features').then(response => {
            const features= response.data.data;
            context.commit('setAdminFeatures', features);
            resolve(response.data);
          }).catch(error => {
            context.commit('setAdminFeatures');
            reject(error);
          })
        })
      }
    },
    retrievePaymentMethods(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('bank/stripe/payment_methods').then(response => {
            const paymentMethods= response.data.data;
            context.commit('setPaymentMethods', paymentMethods);
            resolve(response.data);
          }).catch(error => {
            context.commit('setPaymentMethods');
            reject(error);
          })
        })
      }
    },
    retrievePaymentAmounts(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('bank/stripe/valid_payment_amounts').then(response => {
            const paymentAmounts= response.data.data;
            context.commit('setPaymentAmounts', paymentAmounts);
            resolve(response.data);
          }).catch(error => {
            context.commit('setPaymentAmounts');
            reject(error);
          })
        })
      }
    },
    getGameListMarketPlace(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('getsportlistsMarketPlace').then(response => {
            resolve(response.data);
          }).catch(error => {
            //context.commit('setPaymentAmounts');
            reject(error);
          })
        })
      }
    },
    getFantasyGameList(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('getfantasysportlists').then(response => {
            var result = response.data.sports;
            context.commit('setFantasyGameList', result);
            resolve(response.data);
          }).catch(error => {
            //context.commit('setPaymentAmounts');
            reject(error);
          })
        })
      } 
    },
    getGameList(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('getsportlists').then(response => {
            var result = response.data.sports;
            context.commit('setGameList', result);
            resolve(response.data);
          }).catch(error => {
            //context.commit('setPaymentAmounts');
            reject(error);
          })
        })
      }
    },
    getTeamList(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('getsportsteamlists').then(response => {
            var result = response.data.sports;
            context.commit('setTeamList', result);
            resolve(response.data);
          }).catch(error => {
             
            reject(error);
          })
        })
      }
    },
    getHotorNotList(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('hotornot?'+data.paramters).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    getSportTeam(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('getsportteam?'+data.paramters).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    getDamnnPxList(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('players?'+data.paramters).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }else{
        return new Promise((resolve, reject) => {
          Vue.axios.get('players-preview?'+data.paramters).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    getGainerList(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var param = "";
          if(data.list != "")
          {
            param = data.list;
          }
          Vue.axios.get('player/gainer'+param).then(response => {
          //  var result = response.data.sports;
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    getLoserList(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var param = "";
          if(data.list != "")
          {
            param = data.list;
          }
          
          Vue.axios.get('player/loser'+param).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    getLiveuserList(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var param = "";
          if(data.list != "")
          {
            param = data.list;
          }
          
          Vue.axios.get('getliveplayers'+param).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    }, 
    getTeamPortfolio(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('teamportfolio').then(response => {
          //  var result = response.data.sports;
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    getPortfolio(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('portfolio').then(response => {
          //  var result = response.data.sports;
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    getallTransction(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('getallselldata').then(response => {
             resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    sellTeam(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('team/sell',{user_team_id:data.user_team_id,asking_price:data.price,units:data.unit}).then(response => {
            resolve(response);
          }).catch(error => {
             
            reject(error);
          })
        })
      }         
    },
    sellPlayer(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
       // var userid = context.getters.getUserid;
        return new Promise((resolve, reject) => {
          Vue.axios.post('player/sell',{user_player_id:data.playerid,asking_price:data.price,units:data.unit}).then(response => {
            resolve(response);
          }).catch(error => {
             
            reject(error);
          })
        })
      }         
    },
    buyTeam(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        var userid = context.getters.getUserid;
        var purchasetype = '';
        if(typeof data.purchasetype != 'undefined')
        {
          purchasetype = data.purchasetype;
        }
        return new Promise((resolve, reject) => {
          Vue.axios.post('buy-team',{user_id:userid,team_id:data.teamid,units:data.unit,purchasetype:purchasetype}).then(response => {
            resolve(response);
          }).catch(error => {
             
            reject(error);
          })
        })
      }         
    },
    getallTransctiondata(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/user/transcaction-data/?page='+data.page).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    getUnitsteamandplayer(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/getunits/'+data.type+'/'+data.id).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
        })
      }          
    },
    retrieveTokenRegister(context, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', {
          email: credentials.username,
          password: credentials.password,
         // damnnId:credentials.damnnid

        }).then(response => {
          if (response.data.result.access_token) 
          {
            const token = response.data.result.access_token;
            localStorage.setItem('access_token', token);
            context.commit('retrieveToken', token);
            context.dispatch("retrieveUserDetails");
            resolve(response);
          } else {
            
            const error = {
              response: {
                data: {
                  message: response.data.data.errors.error
                }
              }
            }
            reject(error)
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    buyPlayerDamnn(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        var userid = context.getters.getUserid;
        return new Promise((resolve, reject) => 
        {
          var purchasetype = '';
          if(typeof data.purchasetype != 'undefined')
          {
            purchasetype = data.purchasetype;
          }
          Vue.axios.post('user/buy-player',{user_id:userid,player_id:data.playerid,units:data.unit,purchasetype:purchasetype}).then(response => {
            resolve(response);
            context.dispatch("retrieveBalance");
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    getDividendhistory(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
           Vue.axios.get('userdividend/'+data.selectedmonth).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    teampointsChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('team/points-graphl?team_id='+data.teamid+'&days='+data.days).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    pointsChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('player/points-graphl?player_id='+data.playerid+'&days='+data.days).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    unitsChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('player/units-sold-graphl?player_id='+data.playerid+'&days='+data.days).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    teamunitsChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('team/units-sold-graphl?team_id='+data.teamid+'&days='+data.days).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    dividendChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('player/dividend-graphl?player_id='+data.playerid).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    playerChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('player/graphl-web?player_id='+data.playerid+'&type='+data.type+'&days='+data.days).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    teamChart(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('team/graphl-web?team_id='+data.teamid+'&type='+data.type+'&days='+data.days).then(response => {
            resolve(response.data);
          }).catch(error => {
            
            reject(error);
          })
        })
      }
    },
    teamProfile(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) 
      {
        return new Promise((resolve, reject) => {
          Vue.axios.get('team/profile?team_id='+data.teamid).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    playerProfile(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) 
      {
        return new Promise((resolve, reject) => {
          Vue.axios.get('player/profile?player_id='+data.playerid).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    getTeamBuyandSell(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) 
      {
        return new Promise((resolve, reject) => {
          var userid = context.getters.getUserid;
          var extraparam = '';
          if(typeof data.sport != "undefined")
          {
            extraparam = "&sport="+data.sport;
          } 
          Vue.axios.get('team/trade-list?user_id='+userid+'&mysales='+data.type+extraparam).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    getBuyandSell(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) 
      {
        return new Promise((resolve, reject) => {
          var extraparam = '';
         
          if(data.page != '')
          {
             extraparam += "&page="+data.page
          }
          if(data.extradata != '')
          {
            extraparam += data.extradata;
          } 
          Vue.axios.get('user/trade-list?mysales='+data.type+extraparam).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }
    },
    dashboardData(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('dashboard-data').then(response => {
            resolve(response);
          }).catch(error => {
             
            reject(error);
          })
        })
      }          
    },
    cancelSellPlayer(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        var userid = context.getters.getUserid;
        return new Promise((resolve, reject) => {
          Vue.axios.post('player/cancel',{user_id:userid,user_player_id:data.playerid,units:data.units}).then(response => {
            resolve(response);
          }).catch(error => {
             
            reject(error);
          })
        })
      }         
    },
    cancelSellTeam(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        var userid = context.getters.getUserid;
         
        return new Promise((resolve, reject) => {
          Vue.axios.post('team/cancel',{user_id:userid,user_player_id:data.playerid,units:data.units}).then(response => {
            resolve(response);
          }).catch(error => {
             
            reject(error);
          })
        })
      }         
    },
    getpaymentToken(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('stripe-terminal-token',{amount:data.amount}).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    paymentConfirmed(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('payment-confirmed',{reference:data.reference,CardNumber:data.cardnumber,expiryMonth:data.expmonth,expiryYear:data.expyear,cvv:data.cvv}).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    buyTeambuynsell(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        var userid = context.getters.getUserid;
         
        return new Promise((resolve, reject) => {
          Vue.axios.post('trade-list/team-buy-now',{user_id:userid,sell_id:data.data.sellid,units:data.data.units}).then(response => {
            resolve(response);
            context.dispatch("retrieveBalance");
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    buyPlayerbuynsell(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        var userid = context.getters.getUserid;
        return new Promise((resolve, reject) => {
          let place = '';
          
          if(typeof data.data.place != 'undefined')
          {
             place = data.data.place;
          }  
          Vue.axios.post('trade-list/buy-now',{user_id:userid,sell_id:data.data.sellid,units:data.data.units,place:place}).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    updateAvtar(context,data){
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
     return new Promise((resolve, reject) => 
     {
      axios.post('user/avatar', data, { headers: {'Content-Type': 'multipart/form-data' }}).then(response => 
       {
         //console.log(response.data.data.filename);
           context.commit("setAvatar",response.data.data.filename);
           resolve(response);
        }).catch(error => {
         reject(error);
        })
     })
   },
   updateUserDetails(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        var userid = context.getters.getUserid;
        Vue.axios.post('users/profileupdate/'+userid,{name:data.name}).then(response => {
          resolve(response);
          context.dispatch("retrieveUserDetails");
        }).catch(error => {
          reject(error);
        })
      })
    }         
   },
   changePassword(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        //var userid = context.getters.getUserid;
        Vue.axios.post('users/profileupdate',{password:data.password,oldpassword:data.oldpassword}).then(response => {
          resolve(response);
          context.dispatch("retrieveUserDetails");
        }).catch(error => {
          reject(error);
        })
      })
    }         
   }, 
   getTransactionHistoryTop(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('user/transcactiondata/'+data.days).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getTransactionHistory(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('user/transcaction').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getInvestmentList(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('user/transcaction/purchase').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getsellList(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('user/transcaction/sell').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getdividendBalance(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-dividend-balance').then(response => {
          
          context.commit("setDividendBalance",response.data.data.balance);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getDividendList(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-dividend-player-and-team').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getTeamlisting(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        var parameter = "";
        if(data.game != '')
        {
           parameter = "?sport="+data.game;
        }
        if(data.league != "")
        {
           parameter = parameter+"&league="+data.league;
        } 
        Vue.axios.get('sports/team'+parameter).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   validamount(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('valid-amounts').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   paypalWithdrawl(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        var userid = context.getters.getUserid;
        Vue.axios.post('user/paypal-withdraw-update-wallet/',{user_id:userid,"amount":data.amount}).then(response => {
          resolve(response);
         }).catch(error => {
          reject(error);
        })
      })
    }         
   },
   stateslist(context,data) {
     
    return new Promise((resolve, reject) => {
    
      Vue.axios.post('getstateslist', {
        countryname: data.countryname,
       }).then(response => {
        resolve(response.data);
        //resolve(response);
      }).catch(error => {
        reject(error);
      })
   
    })
  }, 
  citieslist(context,data) {
     
    return new Promise((resolve, reject) => {
    
      Vue.axios.post('getcitieslist', {
        countryname: data.countryname,
        stateid:data.stateid
       }).then(response => {
        resolve(response.data);
        //resolve(response);
      }).catch(error => {
        reject(error);
      })
   
    })
  }, 
   getBonus(context,data)
   {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('gethattrick/'+data.paramters).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getgiftRegions(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('regions').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   getgiftRates(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('rates').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   rewardPurchase(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('rewards/purchase',{region:data.region,"amount":data.amount}).then(response => {
          resolve(response);
         }).catch(error => {
          reject(error);
        })
      })
    }         
   },
   getDashboardPlayer(context,data){
     axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        var type = data.type;
        var limit = data.limit;
        Vue.axios.get('dashboard-player/'+type+"/"+limit).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
   },
   dashboardpointsChart(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('portfolio/points-graphl?days='+data.days).then(response => {
          resolve(response.data);
        }).catch(error => {
          
          reject(error);
        })
      })
    }
  },
  inviteFriend(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('invitefriend',{data:data.data}).then(response => {
          resolve(response);
         }).catch(error => {
          reject(error);
        })
      })
    }         
  },
  getLiveSports(context){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-live-sports').then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
  getLiveMatches(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        var parameter = '';
        if(typeof data  != "undefined")
        {
          parameter = "/fantasy";
        }
        Vue.axios.get('get-live-matches'+parameter).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
  getFixtures(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-fantasy-fixtures/'+data.sport+"/"+data.leagueid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
  getFantasyLadder(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-fantasy-ladder/'+data.sport+"/"+data.leagueid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
  getBasketballFilter(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-basketball-filter/'+data.matchid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },  
  getMatchBoxScore(context,data)
  {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        
        Vue.axios.get('get-match-boxscore/'+data.matchid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
  getMatchCommentry(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-match-commentry/'+data.matchid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
  getMatchCommentryLive(context,data){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
    if (context.getters.loggedIn) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.get('get-match-commentry-live/'+data.matchid+'/'+data.lastloop+'/'+data.lasttime).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    }
  }
  },
  modules: {
  }
})
